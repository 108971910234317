// @generated
// THIS FILE WAS AUTOMATICALLY GENERATED AND SHOULD NOT BE EDITED
/* eslint-disable */
import type * as Types from '../../__generated-gql-types__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LexContextLexFragment = {
  __typename?: 'Lex';
  slug: string;
  isRamped: boolean;
};

export type LexContextLexListQueryVariables = Types.Exact<{
  slugs:
    | Array<Types.Scalars['String']['input']>
    | Types.Scalars['String']['input'];
}>;

export type LexContextLexListQuery = {
  __typename?: 'Query';
  lexListBySlugs: Array<{
    __typename?: 'Lex';
    slug: string;
    isRamped: boolean;
  }>;
};

export const LexContextLexFragmentDoc = gql`
  fragment LexContext_Lex on Lex {
    slug
    isRamped
  }
`;
export const LexContextLexListDocument = gql`
  query LexContext_LexList($slugs: [String!]!) {
    lexListBySlugs(slugs: $slugs) {
      ...LexContext_Lex
    }
  }
  ${LexContextLexFragmentDoc}
`;

/**
 * __useLexContextLexListQuery__
 *
 * To run a query within a React component, call `useLexContextLexListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLexContextLexListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLexContextLexListQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useLexContextLexListQuery(
  baseOptions: Apollo.QueryHookOptions<
    LexContextLexListQuery,
    LexContextLexListQueryVariables
  > &
    (
      | { variables: LexContextLexListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LexContextLexListQuery,
    LexContextLexListQueryVariables
  >(LexContextLexListDocument, options);
}
export function useLexContextLexListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LexContextLexListQuery,
    LexContextLexListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LexContextLexListQuery,
    LexContextLexListQueryVariables
  >(LexContextLexListDocument, options);
}
export function useLexContextLexListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LexContextLexListQuery,
    LexContextLexListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LexContextLexListQuery,
    LexContextLexListQueryVariables
  >(LexContextLexListDocument, options);
}
export type LexContextLexListQueryHookResult = ReturnType<
  typeof useLexContextLexListQuery
>;
export type LexContextLexListLazyQueryHookResult = ReturnType<
  typeof useLexContextLexListLazyQuery
>;
export type LexContextLexListSuspenseQueryHookResult = ReturnType<
  typeof useLexContextLexListSuspenseQuery
>;
export type LexContextLexListQueryResult = Apollo.QueryResult<
  LexContextLexListQuery,
  LexContextLexListQueryVariables
>;
