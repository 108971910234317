import { __DEV__ } from '../constants';

/**
 * The mixpanel library needs to load before we are able to use any of its methods.
 * To avoid requiring `isLoaded` checks everywhere we try to track throughout the codebase
 * (and also to prevent missed tracking calls), we use a buffer and flush system here that
 * waits until the library is fully loaded before invoking any of its methods.
 */

const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_ID ?? '';

let isLoaded = false;

let identifyBuffer: Array<
  Parameters<typeof import('mixpanel-browser').identify>
> = [];
const flushIdentify = () => {
  if (isLoaded) {
    for (const args of identifyBuffer) {
      mixpanel?.identify(...args);
    }
    identifyBuffer = [];
  }
};
const identify: typeof import('mixpanel-browser').identify = (...args) => {
  identifyBuffer.push(args);
  flushIdentify();
};

type SetUserPropertyParams = Parameters<
  typeof import('mixpanel-browser').people.set
>;
let setUserPropertyBuffer: SetUserPropertyParams[] = [];
const flushSetUserProperty = () => {
  if (isLoaded) {
    for (const args of setUserPropertyBuffer) {
      mixpanel?.people.set(...args);
    }
    setUserPropertyBuffer = [];
  }
};
const setUserProperty = (...args: SetUserPropertyParams) => {
  setUserPropertyBuffer.push(args);
  flushSetUserProperty();
};

let trackBuffer: Array<Parameters<typeof import('mixpanel-browser').track>> =
  [];
const flushTrack = () => {
  if (isLoaded) {
    for (const args of trackBuffer) {
      mixpanel?.track(...args);
    }
    trackBuffer = [];
  }
};
const track: typeof import('mixpanel-browser').track = (...args) => {
  trackBuffer.push(args);
  flushTrack();
};

const flushAll = () => {
  flushIdentify();
  flushSetUserProperty();
  flushTrack();
};

let mixpanel: Nullable<typeof import('mixpanel-browser').default> = null;

const mxpnl = {
  init: () => {
    if (mixpanel) return mixpanel;
    return (async () => {
      mixpanel = (await import('mixpanel-browser')).default;
      mixpanel.init(MIXPANEL_TOKEN, {
        debug: __DEV__,
        loaded: () => {
          isLoaded = true;
          flushAll();
        },
      });
      return mixpanel;
    })();
  },
  identify,
  setUserProperty,
  track,
};

export default mxpnl;
