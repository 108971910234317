export const __DEV__ = process.env.NODE_ENV !== 'production';
export const __STAGING__ =
  process.env.NEXT_PUBLIC_APPLICATION_ENV !== 'production';

/**
 * Check whether the current env is server, as opposed to browser
 * @returns whether the current env is server
 */
export const isServer = typeof window === 'undefined';

export const APPLICANT_BASE_URL =
  process.env.NEXT_PUBLIC_LELAND_APPLICANT_URL ?? '';
export const APPLICANT_LOGIN_URL = `${APPLICANT_BASE_URL}/login`;
export const APPLICANT_SIGNUP_URL = `${APPLICANT_BASE_URL}/signup`;

export const APPLICANT_SEARCH_URL = `${APPLICANT_BASE_URL}/search`;
export const APPLICANT_SEARCH_CAREER_URL = `${APPLICANT_SEARCH_URL}/career`;
export const APPLICANT_SEARCH_SCHOOL_URL = `${APPLICANT_SEARCH_URL}/school`;
export const APPLICANT_SEARCH_TEST_URL = `${APPLICANT_SEARCH_URL}/test`;
export const APPLICANT_INBOX_URL = `${APPLICANT_BASE_URL}/inbox`;
export const APPLICANT_SUBSCRIBER_DASHBOARD_URL = `${APPLICANT_BASE_URL}/subscriber/dashboard`;

export const FREE_MONTH_APPLY_URL = `${APPLICANT_BASE_URL}/freemonth/apply`;

export const COACH_BASE_URL =
  process.env.NEXT_PUBLIC_LELAND_COACH_URL ?? 'https://coach.joinleland.com';
export const COACH_SIGNUP_URL = `${COACH_BASE_URL}/signup`;

export const LIBRARY_BASE_URL = '/library';
export const LIBRARY_ARTICLE_BASE_URL = `${LIBRARY_BASE_URL}/a`;

export const COMPANY_INSTAGRAM_URL = 'https://www.instagram.com/joinleland/';
export const COMPANY_LINKEDIN_URL =
  'https://www.linkedin.com/company/joinleland/';
export const COMPANY_TIKTOK_URL = 'https://www.tiktok.com/@joinleland';

export const LELAND_COMMUNITY_SLACK_URL =
  'https://join.slack.com/t/lelandcommunity/shared_invite/zt-wkgna60h-Y~lAMoDXdJ6~09o1J4jKhA';
export const SUPPORT_URL = '/support';

export const LELAND_DOMAIN =
  __DEV__ || __STAGING__ ? '.leland-staging.com' : '.joinleland.com';

export const CLASS_REFERRAL_BANNER_PROPS = {
  href: 'https://joinleland.typeform.com/to/h3swe9pQ',
  text: 'Give $10, get $10 off for every friend that signs up for a class!',
  emoji: '💰',
};

export const SUPPORT_TYPEFORM_URL =
  'https://joinleland.typeform.com/to/TY5OgGwK';

export const BASE_LELAND_GO_URL = process.env.NEXT_PUBLIC_LELAND_GO_URL;

export const PARTNERSHIPS_LP_URL = `${BASE_LELAND_GO_URL}/partner/partnerships`;

export const BASE_LELAND_PLUS_MARKETING_URL = `${BASE_LELAND_GO_URL}/plus`;
export const LELAND_PLUS_REDIRECT_URL = `${APPLICANT_BASE_URL}/leland-plus`;

export const POSITIVE_INT_PATTERN = /^[1-9]\d*$/;

export const DEFERRED_MBA_SUBCATEGORY_SLUG = 'deferred-mba';

export const MBA_CATEGORY_SLUG = 'mba';
export const MANAGEMENT_CONSULTING_CATEGORY_SLUG = 'management-consulting';
export const COLLEGE_CATEGORY_SLUG = 'college';
export const GMAT_CATEGORY_SLUG = 'gmat';
export const GRE_CATEGORY_SLUG = 'gre';
export const PRODUCT_MANAGEMENT_CATEGORY_SLUG = 'product-management';
export const CAREER_CATEGORY_SLUG = 'career-development';
export const MEDICAL_SCHOOL_CATEGORY_SLUG = 'medical-school';
export const SUBSCRIBER_DUMMY_COACH_URN = 'urn:coach:60b2cb327b8ead4a78c16fc0';
export const MASTERS_PROGRAMS_SLUG = 'masters-programs';
export const LAW_SCHOOL_SLUG = 'law-school';
export const INVESTMENT_BANKING_SLUG = 'investment-banking';

export const CATEGORY_SLUGS = {
  MBA: MBA_CATEGORY_SLUG,
  ManagementConsulting: MANAGEMENT_CONSULTING_CATEGORY_SLUG,
  GMAT: GMAT_CATEGORY_SLUG,
  GRE: GRE_CATEGORY_SLUG,
  College: COLLEGE_CATEGORY_SLUG,
  DentalSchool: 'dental-school',
  InvestmentBanking: INVESTMENT_BANKING_SLUG,
  LawSchool: LAW_SCHOOL_SLUG,
  MedicalSchool: MEDICAL_SCHOOL_CATEGORY_SLUG,
  ProductManagement: PRODUCT_MANAGEMENT_CATEGORY_SLUG,
  PrivateEquity: 'private-equity',
  MastersPrograms: MASTERS_PROGRAMS_SLUG,
};

export const CALENDLY_LINKS = {
  STRATEGY_CALL: 'https://calendly.com/d/zy6g-mpsk/leland-strategy-call',
  MEET_WITH_TEAM_MEMBER:
    'https://calendly.com/d/4cs-hhf-zsx/meet-with-the-leland-team',
};

export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please try again. If the issue persists, contact Leland support at support@joinleland.com.';

// TODO - pull these numbers from BE
export const NUM_CUSTOMERS_STR = `100,000+`;
export const NUM_REVIEWS_STR = '10,000+';
export const NUM_CATEGORIES_STR = '50+';
