import Cookies from 'js-cookie';

import { type Scalars } from '../__generated-gql-types__/globalTypes';

import { LELAND_DOMAIN } from './constants';

const IMPERSONATED_USER_KEY = 'leland-IMPERSONATED_USER_KEY';

interface ILelandImpersonation {
  getImpersonatedUserId: () => Nullable<Scalars['UserUrn']['output']>;
  setImpersonatedUserId: (userUrn: Scalars['UserUrn']['output']) => void;
  removeImpersonatedUserId: () => void;
}

// prefer LelandLocalStorage, fallback to cookies (for cross-subdomain support)
export const LelandImpersonation: ILelandImpersonation = {
  getImpersonatedUserId: () => Cookies.get(IMPERSONATED_USER_KEY) ?? null,
  setImpersonatedUserId: (userUrn) =>
    Cookies.set(IMPERSONATED_USER_KEY, userUrn, {
      domain: LELAND_DOMAIN,
    }),
  removeImpersonatedUserId: () =>
    Cookies.remove(IMPERSONATED_USER_KEY, {
      domain: LELAND_DOMAIN,
    }),
};
