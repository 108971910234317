import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import _searchEngineConfig from '../assets/jsons/search_engines.json';
import { LELAND_DOMAIN } from '../utils/constants';

const maxAge = '86400'; // 1 day expiration

const searchEngineConfig = _searchEngineConfig as SearchEngineConfigInterface;

export const useAttribution = () => {
  const router = useRouter();
  const query = router.query;

  useEffect(() => {
    if (router.isReady) {
      const referrer = document.referrer;
      const referrerDomain = getSearchDomain(referrer);
      const referrerSourceMedium = getSourceMedium(referrerDomain);
      if (
        query.gclid ??
        query.utm_source ??
        query.utm_medium ??
        query.utm_campaign ??
        referrerSourceMedium
      ) {
        if (query.gclid) {
          Cookies.set('gclid', query.gclid as string, {
            domain: LELAND_DOMAIN,
            'max-age': maxAge,
          });
        } else {
          Cookies.remove('gclid');
        }
        const source =
          (query.utm_source as Optional<string>) ??
          referrerSourceMedium?.source;
        if (source) {
          Cookies.set('source', source, {
            domain: LELAND_DOMAIN,
            'max-age': maxAge,
          });
        } else {
          Cookies.remove('source');
        }
        const medium =
          (query.utm_medium as Optional<string>) ??
          referrerSourceMedium?.medium;
        if (medium) {
          Cookies.set('medium', medium, {
            domain: LELAND_DOMAIN,
            'max-age': maxAge,
          });
        } else {
          Cookies.remove('medium');
        }
        if (query.utm_campaign) {
          Cookies.set('campaign', query.utm_campaign as string, {
            domain: LELAND_DOMAIN,
            'max-age': maxAge,
          });
        } else {
          Cookies.remove('campaign');
        }
        Cookies.set('referrer', document.referrer, {
          domain: LELAND_DOMAIN,
          'max-age': maxAge,
        });
      }
    }
  }, [
    router.isReady,
    query.gclid,
    query.utm_medium,
    query.utm_source,
    query.utm_campaign,
  ]);
};

type SearchEngineConfigInterface = Record<
  string,
  {
    p?: string;
    n: string;
    regex?: boolean;
  }
>;

interface SourceMedium {
  source: string;
  medium: string;
}

function getSourceMedium(
  referringDomain: Possible<string>,
): Nullable<SourceMedium> {
  if (!referringDomain) {
    return null;
  }

  if (
    Object.prototype.hasOwnProperty.call(searchEngineConfig, referringDomain)
  ) {
    return {
      source: searchEngineConfig[referringDomain].n,
      medium: 'organic',
    };
  }

  // This bit takes only those searchEngineConfigs where regex == true
  // if referring domain isn't otherwise found, we will match against regex, but only then
  const filteredSearchEngineConfig = Object.keys(searchEngineConfig).reduce(
    function (r: SearchEngineConfigInterface, e: string) {
      if (searchEngineConfig[e].regex) r[e] = searchEngineConfig[e];
      return r;
    },
    {},
  );

  for (const key in filteredSearchEngineConfig) {
    if (referringDomain.match(key) != null) {
      return {
        source: searchEngineConfig[key].n,
        medium: 'organic',
      };
    }
  }

  return null;
}

function getSearchDomain(url: Possible<string>): Nullable<string> {
  if (!url) {
    return null;
  }

  const hostnameParts = new URL(
    url.startsWith('http') ? url : 'https://' + url,
  ).hostname.split('.');
  const lastTwo = hostnameParts.slice(-2);

  if (['com', 'co'].includes(lastTwo[0]) && lastTwo.join('').length <= 5) {
    return hostnameParts.slice(-3).join('.');
  }

  return hostnameParts.slice(-2).join('.');
}
