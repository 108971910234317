import { useApolloClient } from '@apollo/client';
import { omit } from 'lodash-es';
import type React from 'react';
import { createContext, useContext, useEffect, useMemo } from 'react';

import { trackGtm } from '../lib/gtm';
import mxpnl from '../utils/analytics/mixpanel';

import {
  AuthContextDocument,
  type AuthContextUserFragment,
  type SignupMutationVariables,
  useAuthContextQuery,
  useLoginMutation,
  useRequestLoginVerificationCodeMutation,
  useRequestSignupVerificationCodeMutation,
  useSignupAsCoachMutation,
  useSignupMutation,
} from './__generated-gql-types__/AuthContext.generated';

interface AuthContext {
  currentUser: Possible<AuthContextUserFragment>;
  errorLoadingUser?: Error;
  isLoadingUser: boolean;
  isImpersonating: boolean;
  isNewSubscriptionExperience: boolean;
  /**
   * In most cases, current user would automatically be updated by Apollo, unless the cached one is null.
   */
  setCurrentUser: (data: AuthContextUserFragment) => void;
  trackSignUp: (userId: string, coachId?: string) => void;
}

const missingAuthProvider = 'You forgot to wrap your app in <AuthProvider>';

export const AuthContext = createContext<AuthContext>({
  get currentUser(): never {
    throw new Error(missingAuthProvider);
  },
  get errorLoadingUser(): never {
    throw new Error(missingAuthProvider);
  },
  get isLoadingUser(): never {
    throw new Error(missingAuthProvider);
  },
  get isNewSubscriptionExperience(): never {
    throw new Error(missingAuthProvider);
  },
  get isImpersonating(): never {
    throw new Error(missingAuthProvider);
  },
  get setCurrentUser(): never {
    throw new Error(missingAuthProvider);
  },
  get trackSignUp(): never {
    throw new Error(missingAuthProvider);
  },
});

const trackSignUp = (userId: string, coachId?: string) => {
  trackGtm('coachSignUp', { userId, ...(coachId ? { coachId } : {}) });
};

export const useAuth: () => AuthContext = () =>
  useContext<AuthContext>(AuthContext);

interface RequestVerificationCodeReturn {
  requestVerificationCode: (email: string) => Promise<void>;
  verificationCodeLoading: boolean;
}

export const useLogin = (): RequestVerificationCodeReturn & {
  login: (email: string, code: string) => Promise<AuthContextUserFragment>;
  loginLoading: boolean;
} => {
  const apolloClient = useApolloClient();
  const [login, { loading: loginLoading }] = useLoginMutation();
  const [requestVerificationCode, { loading: verificationCodeLoading }] =
    useRequestLoginVerificationCodeMutation();

  return useMemo(
    () => ({
      login: async (email: string, code: string) => {
        const { data, errors } = await login({
          variables: { email, code },
        });
        if (!data || errors?.length) {
          console.warn(errors);
          throw new Error('login operation failed');
        }

        trackGtm('login', {
          userId: data.login.id,
          ...(data.login.coach?.id ? { coachId: data.login.coach?.id } : {}),
        });

        apolloClient.writeQuery({
          query: AuthContextDocument,
          data: { user: data.login },
        });

        return data.login;
      },
      loginLoading,
      requestVerificationCode: async (email: string) => {
        const { data, errors } = await requestVerificationCode({
          variables: { email },
        });
        if (!data?.requestLoginSecurityCode || errors?.length) {
          console.warn(errors);
          throw new Error('requestVerificationCode operation failed');
        }
      },
      verificationCodeLoading,
    }),
    [
      apolloClient,
      login,
      loginLoading,
      requestVerificationCode,
      verificationCodeLoading,
    ],
  );
};

export const useSignup = (): RequestVerificationCodeReturn & {
  signup: (vars: SignupMutationVariables) => Promise<AuthContextUserFragment>;
  signupLoading: boolean;
  signupAsCoach: () => Promise<AuthContextUserFragment>;
  signupAsCoachLoading: boolean;
} => {
  const apolloClient = useApolloClient();
  const [signup, { loading: signupLoading }] = useSignupMutation();
  const [signupAsCoach, { loading: signupAsCoachLoading }] =
    useSignupAsCoachMutation();
  const [requestVerificationCode, { loading: verificationCodeLoading }] =
    useRequestSignupVerificationCodeMutation();

  return useMemo(
    () => ({
      signup: async (variables: SignupMutationVariables) => {
        const { data, errors } = await signup({ variables });

        if (!data || errors) {
          console.warn(errors);
          throw new Error('signup operation failed');
        }

        apolloClient.writeQuery({
          query: AuthContextDocument,
          data: { user: data.signup },
        });

        trackSignUp(data.signup.id, data.signup.coach?.id);

        return data.signup;
      },
      signupLoading,
      signupAsCoach: async () => {
        const { data, errors } = await signupAsCoach();

        if (!data || errors) {
          console.warn(errors);
          throw new Error('signupAsCoach operation failed');
        }

        apolloClient.writeQuery({
          query: AuthContextDocument,
          data: { user: data.signupAsCoach },
        });

        trackSignUp(data.signupAsCoach.id, data.signupAsCoach.coach?.id);

        return data.signupAsCoach;
      },
      signupAsCoachLoading,
      requestVerificationCode: async (email: string) => {
        const { data, errors } = await requestVerificationCode({
          variables: { email },
        });
        if (!data?.requestSignupSecurityCode || errors?.length) {
          console.warn(errors);
          throw new Error('requestVerificationCode operation failed');
        }
      },
      verificationCodeLoading,
    }),
    [
      apolloClient,
      requestVerificationCode,
      signup,
      signupAsCoach,
      signupAsCoachLoading,
      signupLoading,
      verificationCodeLoading,
    ],
  );
};

interface Props {
  children: React.ReactNode;
}

export default function AuthContextProvider({
  children,
}: Props): React.ReactElement {
  const apolloClient = useApolloClient();
  const { data, loading, error } = useAuthContextQuery();

  const context: AuthContext = useMemo(
    () => ({
      currentUser: data?.user,
      errorLoadingUser: error,
      isLoadingUser: loading,
      isImpersonating: data?.user?.impersonator != null,
      isNewSubscriptionExperience: !!data?.user?.applicant?.newExperience,
      setCurrentUser: (data: AuthContextUserFragment) => {
        apolloClient.writeQuery({
          query: AuthContextDocument,
          data: { user: data },
        });
      },
      trackSignUp,
    }),
    [apolloClient, data?.user, error, loading],
  );
  useEffect(() => {
    if (data?.user?.id != null) {
      const idsObj: Record<string, string> = {
        userId: data.user.id,
      };
      if (data.user.applicant?.id) {
        idsObj.userApplicantId = data.user.applicant.id;
      }

      mxpnl.identify(data.user.id);
      mxpnl.setUserProperty({
        $email: data.user.email,
        $first_name: data.user.firstName,
        $last_name: data.user.lastName,
        $avatar: data.user.pictureLink,
        ...idsObj,
        ...omit(data.user.userSecrets?.firstContact ?? {}, '__typename'),
      });

      trackGtm(undefined, idsObj);
    }
  }, [
    data?.user?.applicant?.id,
    data?.user?.email,
    data?.user?.firstName,
    data?.user?.id,
    data?.user?.lastName,
    data?.user?.pictureLink,
    data?.user?.userSecrets?.firstContact,
  ]);

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}
