import { useRouter } from 'next/router';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { __DEV__ } from '../utils/constants';

declare global {
  interface Window {
    dataLayer: Array<Record<string, unknown>>;
  }
}

let tagManagerInitialized = false;

const init = (): void => {
  if (!tagManagerInitialized) {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? '',
      auth: process.env.NEXT_PUBLIC_GTM_AUTH,
      preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
    });
    tagManagerInitialized = true;
  }
};

export const trackGtm = (
  event: Optional<string>,
  params: Record<string, unknown> = {},
): void => {
  const gtmEvent = {
    ...(event ? { event } : {}),
    ...params,
  };

  if (__DEV__) {
    console.log('GTM', gtmEvent);
    return;
  }

  init();
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push(gtmEvent);
};

export const useGtm = (): void => {
  const { events, isReady } = useRouter();

  useEffect(() => {
    if (isReady) {
      trackGtm('virtualPageView', {
        pageTitle: document.title,
        pageUrl: document.location.pathname + document.location.search,
      });
    }
  }, [isReady]);

  useEffect(() => {
    const handleRouteChange = (pageUrl: string) => {
      trackGtm('virtualPageView', {
        pageTitle: document.title,
        pageUrl,
      });
    };

    events.on('routeChangeComplete', handleRouteChange);

    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);
};
