import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { trackGtm } from '../../lib/gtm';
import { parseQs } from '../query-params';

import mxpnl from './mixpanel';

const trimQs = (url: string) => url.split('?')[0];

export const usePageView = (): void => {
  const router = useRouter();

  useEffect(() => {
    const eventData = {
      pageTitle: document.title,
      pageUrl: trimQs(document.location.pathname),
      ...parseQs(document.location.search),
    };
    trackGtm('virtualPageView', eventData);
    mxpnl.track('pageView', eventData);
  }, []);

  useEffect(() => {
    const handleRouteChange = (pageUrl: string) => {
      const eventData = {
        pageTitle: document.title,
        pageUrl: trimQs(pageUrl),
        ...parseQs(pageUrl),
      };
      trackGtm('virtualPageView', eventData);
      mxpnl.track('pageView', eventData);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};
